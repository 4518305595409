import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import baseURL from "../../Constants/api_url";
import { IoCheckmarkDone } from "react-icons/io5";
import { format } from "date-fns";
import axios from "axios";
import RifaNumero from "../../Pages/Home/SorteioPage/cotas/RifaNumero.js";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

import "./PaymentForm.css";
import ErrorPopup from "../../Services/Auth/Error/ErrorPopup.js";
import { isValidCPF } from "../../utils/ValidarCpf.js";

function PaymentForm({
  transaction_amount,
  userUID,
  idSorteio,
  quantidadeRifas,
  closePaymentPopup,
}) {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [identificationNumber, setDocNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [copied, setCopied] = useState(false);
  const [timer, setTimer] = useState(600);
  const [data, setData] = useState();
  const [rifas, setRifas] = useState();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateCPF = () => {
    const isValid = isValidCPF(identificationNumber);
    if (!isValid) {
      setErrorMessage("CPF inválido. Por favor, verifique e tente novamente.");
    }
    return isValid;
  };

  const handleCopyClick = () => {
    const qrCode =
      paymentResponse.paymentData.point_of_interaction.transaction_data.qr_code;
    navigator.clipboard.writeText(qrCode).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    const storedPhone = localStorage.getItem("userNumber");

    setEmail(storedEmail || "");
    setPhoneNumber(storedPhone || "");
  }, []);

  useEffect(() => {
    let interval;
    if (paymentResponse && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [paymentResponse, timer]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSubmit = async (e) => {
    const identificationType = "CPF";
    e.preventDefault();
    if (!email || !identificationNumber) {
      setError("Preencha todos os campos.");
      return;
    }

    if (!validateCPF()) {
      setErrorModalOpen(true);
      return;
    }

    setLoading(true);

    const paymentData = {
      transaction_amount,
      email,
      identificationType,
      identificationNumber,
      quantidadeRifas,
      userUID,
      idSorteio,
    };

    try {
      setData(format(new Date(), "dd/MM/yyyy' 'HH:mm:ss"));
      const response = await fetch(`${baseURL}/payment/fazer-pagamento`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "true",
        },
        body: JSON.stringify(paymentData),
      });

      const result = await response.json();
      setPaymentResponse(result);
    } catch (error) {
      setError("Erro ao processar o pagamento: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const confirmarPagamento = async () => {
    setLoading(true);
    const pedidoId = paymentResponse.paymentData.id;
    try {
      const response = await axios.post(
        `${baseURL}/payment/webhook-mercadopago`,
        {
          action: "verify.payment",
          data: {
            id: pedidoId,
          },
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      setLoading(false);

      const rifasGeradas = response.data.rifas;

      setRifas(rifasGeradas);
    } catch (error) {
      setLoading(false);
      setError("Erro ao confirmar o pagamento: " + error.message);
    }
  };

  return (
    <div className="payment-form">
      {paymentResponse ? (
        <div className="payment-confirmation">
          {!rifas && (
            <>
              <div className="timer">
                <p>Tempo restante para pagamento:</p>
                <h1>{formatTime()}</h1>
              </div>
              <div className="qr-code">
                <img
                  src={`data:image/png;base64,${paymentResponse.paymentData.point_of_interaction.transaction_data.qr_code_base64}`}
                  alt="Código QR do Pix"
                  width={200}
                />
              </div>
              <div className="pix-code">
                <p>
                  {
                    paymentResponse.paymentData.point_of_interaction
                      .transaction_data.qr_code
                  }
                </p>
                <button onClick={handleCopyClick}>
                  {copied ? "COPIADO!" : "COPIAR"}
                </button>
              </div>
            </>
          )}
          {rifas && rifas.length > 0 ? (
            <div></div>
          ) : (
            <div className="confirm-payment">
              <button onClick={confirmarPagamento} disabled={loading}>
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <>
                    <IoCheckmarkDone /> CONFIRMAR PAGAMENTO
                  </>
                )}
              </button>
            </div>
          )}

          {rifas && (
            <div>
              <div className="user-numbers">
                <div className="grid-user-cotas">
                  {rifas.map((numero, index) => (
                    <p key={index}>
                      <RifaNumero key={index} numero={String(numero)} />
                    </p>
                  ))}
                </div>
              </div>
              <div className="ver-tudo">
                <Link to="/meus-titulos">
                  <button>VER TUDO</button>
                </Link>
              </div>
            </div>
          )}

          <div className="payment-information">
            <p style={{ textAlign: "center" }}>MAIS DETALHES DA COMPRA</p>
            <p style={{ fontSize: "14px" }}>
              IDENTIFICADOR: {paymentResponse.paymentData.id}
            </p>
            {/* <div className="user-name">
              Comprador:{" "}
              {
                paymentResponse.paymentData.point_of_interaction
                  .transaction_data.bank_info.collector.account_holder_name
              }
            </div> */}
            <div className="user-cpf">
              CPF: {identificationNumber.slice(0, 3)}
              {".***.***-**"}
            </div>

            <div className="user-fone">
              Telefone: {localStorage.getItem("userNumber")}
            </div>
            <div className="date">Data/horário: {data}</div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input
              id="email"
              type="email"
              readOnly
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Digite seu e-mail"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Telefone</label>
            <InputMask
              id="phoneNumber"
              mask="(99) 99999-9999"
              readOnly
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Digite seu número"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cpf">CPF</label>
            <InputMask
              id="cpf"
              mask="999.999.999-99"
              value={identificationNumber}
              onChange={(e) => setDocNumber(e.target.value)}
              placeholder="Digite seu CPF"
              required
            />
          </div>
          <div className="form-group">
            <button className="confirm-button" type="submit" disabled={loading}>
              {loading ? "Aguarde..." : "CONFIRMAR"}
            </button>
            <p>*clique fora do formulário para fechar</p>
          </div>
        </form>
      )}
      {errorModalOpen && (
        <ErrorPopup
          message={errorMessage}
          onClose={() => setErrorModalOpen(false)}
        />
      )}
    </div>
  );
}

export default PaymentForm;

import React, { useEffect, useState } from "react";
import axios from "axios";
import AddDraw from "../../Services/FormSorteios/AddDraw";
import { FaWhatsapp, FaRegEdit } from "react-icons/fa";

import { IoSearchOutline } from "react-icons/io5";
import { GoEye } from "react-icons/go";
import { CircularProgress } from "@mui/material";
import gold from "../../Assets/icons/gold.png";
import silver from "../../Assets/icons/silver.png";
import bronze from "../../Assets/icons/bronze.png";
import baseURL from "../../Constants/api_url";

import { Link } from "react-router-dom";
import "./AdmHomePage.css";

function AdmHomePage() {
  const [sorteios, setSorteios] = useState([]);
  const [search, setSearch] = useState("");
  const [user, setUser] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [idSorteio, setIdSorteio] = useState("");
  const [idRanking, setIdRanking] = useState(0);
  const [loading, setLoading] = useState(false);
  const [noWinnerMessage, setNoWinnerMessage] = useState("");
  const [ranking, setRanking] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [sorteioEdit, setSorteioEdit] = useState();

  const icons = [gold, silver, bronze];

  const openPopUp = () => {
    setIsPopUpOpen(true);
  };

  const closePopUp = () => {
    recuperarTodosSorteios();
    setIsPopUpOpen(false);
    setSorteioEdit(null);
    setEditMode(false);
  };

  function abrirLinkWhatsapp() {
    const numero = user.numeroTelefone.replace(/[\s()-]/g, "");
    const urlWhatsapp = `https://api.whatsapp.com/send?phone=55${numero}`;
    window.open(urlWhatsapp, "_blank");
  }

  const handleBackgroundClick = (e) => {
    closePopUp();
  };

  const handleFormClick = (e) => {
    e.stopPropagation();
  };

  async function handleSearchConfirm() {
    try {
      const response = await axios.post(`${baseURL}/rifa/buscar-cota`, {
        idSorteio: idSorteio,
        numero: search,
      });

      if (response.data) {
        setUser(response.data);

        setNoWinnerMessage("");
      } else {
        setUser(null);
        setNoWinnerMessage("Não houve ganhador para este sorteio!");
      }
    } catch (error) {
    } finally {
    }
  }

  async function getRanking() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/sorteio/get-ranking/${idRanking}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      setRanking(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function recuperarTodosSorteios() {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/sorteio/get-sorteios`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      });
      setSorteios(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const userUID = localStorage.getItem("userUID");

    if (
      userUID !== "pLDJQpksZmZ1j7pIXgwj8xWRQvf2" &&
      userUID !== "fJPeG5wmXbOSAX72ocEC2b4t94C3" &&
      userUID !== "p1UpNULOddb5Hgk1Xv0wgLDVWUA3"
    ) {
      window.location.href = "/";
    } else {
      recuperarTodosSorteios();
    }
  }, []);

  return (
    <div className="adm-home-page">
      <div className="search-area">
        <h1>BUSCAR COTA</h1>
        <div className="search-input">
          <select
            value={idSorteio}
            onChange={(event) => setIdSorteio(event.target.value)}
          >
            <option value="">Selecione o sorteio</option>
            {sorteios
              .slice()
              .reverse()
              .map((sorteio) => (
                <option key={sorteio.id} value={sorteio.id}>
                  {sorteio.titulo}
                </option>
              ))}
          </select>

          <input
            type="text"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            placeholder="Insira o número"
          />
          <button onClick={() => handleSearchConfirm()}>
            <IoSearchOutline />
          </button>
        </div>

        {user && (
          <>
            <br />
            <div>
              <h1>COTA PREMIADA:</h1>
            </div>
            <div className="vencedor">
              <div>
                <img
                  className="img-sorteio"
                  src={
                    sorteios.find(
                      (sorteio) => sorteio.id === parseInt(idSorteio)
                    )?.imageURL
                  }
                  alt="Sorteio"
                />
              </div>
              <div className="vencedor-card">
                <div className="vencedor-information">
                  <h6>{user.username}</h6>
                  <p>{user.email}</p>
                  <p>{user.numeroTelefone}</p>
                </div>
                <div className="vencedor-cota">
                  <p>
                    <span>
                      Cota sorteada: <b>{user.numeroSorteado}</b>
                    </span>
                  </p>
                </div>
                <button onClick={() => abrirLinkWhatsapp()}>
                  <FaWhatsapp />
                  ENTRAR EM CONTATO
                </button>
              </div>
            </div>
          </>
        )}

        <br />

        {noWinnerMessage && <h6>{noWinnerMessage}</h6>}
      </div>
      <br />
      <br />
      <div className="adm-sorteios">
        <h6>
          {sorteios.length > 0
            ? "SORTEIOS DISPONÍVEIS:"
            : "NÃO HÁ SORTEIOS NO MOMENTO!"}
        </h6>

        <div className="criar-sorteio">
          <button onClick={openPopUp}>CRIAR SORTEIO +</button>
        </div>

        <div className="cards-sorteios">
          {sorteios
            .slice()
            .reverse()
            .map((sorteio) => (
              <div key={sorteio.id} className="card-sorteio">
                <img src={sorteio.imageURL} alt={sorteio.titulo} />
                <div className="card-sorteio-info">
                  <h6>{sorteio.titulo}</h6>
                  <p>{sorteio.descricao}</p>
                </div>
                <div className="card-menu">
                  <Link to={`/sorteio/${sorteio.id}`}>
                    <button>
                      <GoEye />
                    </button>
                  </Link>
                  <button
                    style={{ backgroundColor: "#fff" }}
                    onClick={() => {
                      setEditMode(true);
                      setSorteioEdit(sorteio);
                      openPopUp(true);
                    }}
                  >
                    <FaRegEdit color="#000" />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <br />
      <h1 style={{ color: "#fff" }}>BUSCAR RANKING</h1>
      <div className="select-ranking">
        <select
          value={idRanking}
          onChange={(event) => {
            setIdRanking(event.target.value);
          }}
        >
          <option value="">Selecione o sorteio</option>
          {sorteios
            .slice()
            .reverse()
            .map((sorteio) => (
              <option key={sorteio.id} value={sorteio.id}>
                {sorteio.titulo}
              </option>
            ))}
        </select>
        <button onClick={() => getRanking()}>
          <IoSearchOutline color="#fff" />
        </button>
      </div>
      {loading ? (
        <div className="loading-anim">
          <CircularProgress size={48} color="inherit" />
          <h6>BUSCANDO</h6>
        </div>
      ) : (
        <div>
          {ranking && ranking.length > 0 ? (
            <div className="ranking-bg">
              <h6>RANKING</h6>

              <div className="ranking">
                {ranking && ranking.length > 0 ? (
                  ranking.map((usuario, index) => (
                    <div
                      key={index}
                      className="posicao"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const numero = usuario.telefone.replace(/[\s()-]/g, "");
                        const urlWhatsapp = `https://api.whatsapp.com/send?phone=55${numero}`;
                        window.open(urlWhatsapp, "_blank");
                      }}
                    >
                      <img
                        src={icons[index % icons.length]}
                        alt="icone"
                        width={20}
                        height={30}
                      />
                      <p>{`${usuario.username}`}</p>

                      <div className="quant-cotas">
                        <h6>{`${usuario.totalCotas}`}</h6>
                        <h5>bilhetes</h5>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Sem dados de ranking disponíveis para este sorteio.</p>
                )}
              </div>
              <p>
                {"Sorteio: "}
                <b>
                  {
                    sorteios.find(
                      (sorteio) => sorteio.id === parseInt(idRanking)
                    )?.titulo
                  }
                </b>
              </p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}

      {isPopUpOpen && (
        <div className="popup-background" onClick={handleBackgroundClick}>
          <div className="popup" onClick={handleFormClick}>
            <div className="popup-content">
              <AddDraw
                handleBackgroundClick={handleBackgroundClick}
                editMode={editMode}
                sorteioInfo={sorteioEdit}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdmHomePage;

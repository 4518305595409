
export const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");

  if (cpf === "" || cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

  let result = 0;
  let digit = 0;

  for (let i = 1; i <= 9; i++)
    result = result + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  digit = (result * 10) % 11;

  if (digit === 10 || digit === 11) digit = 0;

  if (digit !== parseInt(cpf.substring(9, 10))) return false;

  result = 0;
  for (let i = 1; i <= 10; i++)
    result = result + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  digit = (result * 10) % 11;

  if (digit === 10 || digit === 11) digit = 0;

  if (digit !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

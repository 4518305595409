import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import axios from "axios";
import "./SorteioPage.css";
import PaymentForm from "../../../Components/PaymentForm/PaymentForm";
import baseURL from "../../../Constants/api_url";
import { CircularProgress } from "@mui/material";
import Login from "../../../Services/Auth/Auth.js";
import gold from "../../../Assets/icons/gold.png";
import silver from "../../../Assets/icons/silver.png";
import bronze from "../../../Assets/icons/bronze.png";
import { IoLogoWhatsapp } from "react-icons/io5";

function SorteioPage() {
  const [sorteioData, setSorteioData] = useState(null);
  const [count, setCount] = useState(sorteioData?.min_cotas || 0);
  const [total, setTotal] = useState(0);
  const [minCotas, setMinCotas] = useState(0);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isPromoPaymentOpen, setIsPromoPaymentOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [cotasEsgotadas, setCotasEsgotadas] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ranking, setRanking] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [selectedPromotionCount, setSelectedPromotionCount] = useState(0);
  const [selectedPromotionTotal, setSelectedPromotionTotal] = useState(0);

  const [promotions, setPromotions] = useState({});

  const icons = [gold, silver, bronze];

  function abrirLinkWhatsapp() {
    var urlWhatsapp = "https://api.whatsapp.com/send?phone=5579996592595";
    window.open(urlWhatsapp, "_blank");
  }

  const handleOpenLogin = () => {
    setIsLoginOpen(true);
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();
  };

  const { id } = useParams();
  const userUID = localStorage.getItem("userUID");

  useEffect(() => {
    const fetchSorteioData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/sorteio/get-sorteio/${id}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        );

        setSorteioData(response.data);
        setCount(response.data?.min_cotas);
        setMinCotas(response.data?.min_cotas);
        if (response.data?.promocoes) {
          setPromotions(JSON.parse(response.data?.promocoes));
        }
        const percentRemaining =
          (response.data?.quantidade_restante / 1000000) * 100;

        if (percentRemaining <= 20) {
          setShowWarning(true);
        }
        if (response.data?.quantidade_restante === 0) {
          setCotasEsgotadas(true);
          setShowWarning(false);
        }
        setIsLoading(false);
      } catch (error) {}
    };

    async function getRanking() {
      try {
        const response = await axios.get(
          `${baseURL}/sorteio/get-ranking/${id}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        );

        setRanking(response.data);
      } catch (error) {}
    }

    fetchSorteioData();
    getRanking();
  }, [id, sorteioData?.imageURL, sorteioData?.min_cotas, sorteioData?.titulo]);

  const increment = () => {
    if (count < sorteioData?.quantidade_restante) {
      setCount((prevCount) =>
        typeof prevCount === "number" ? prevCount + 1 : 1
      );
    }
  };

  const decrement = () => {
    if (count > sorteioData?.min_cotas) {
      setCount(count - 1);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (
      newValue === "" ||
      (!isNaN(Number(newValue)) &&
        Number(newValue) >= minCotas &&
        Number(newValue) <= sorteioData?.quantidade_restante)
    ) {
      setCount(newValue === "" ? "" : Number(newValue));
    }
  };

  const handleButtonClick = (value) => {
    const newValue = count + value;
    if (newValue <= sorteioData?.quantidade_restante) {
      setCount(newValue);
    }
  };

  const handleClear = () => {
    setCount(sorteioData?.min_cotas);
  };

  useEffect(() => {
    const calculateTotal = () => {
      const cotaPrice = sorteioData?.valorCota || 0;
      const totalPrice = count * cotaPrice;
      setTotal(totalPrice);
    };
    if (sorteioData) {
      calculateTotal();
    }
  }, [count, selectedPromotion, sorteioData, sorteioData?.valorCota]);

  const openPaymentPopup = () => {
    if (userUID) {
      if (isPromoPaymentOpen) {
      } else {
        setIsPaymentOpen(true);
      }
    } else {
      handleOpenLogin();
    }
  };

  const closePaymentPopup = () => {
    setSelectedPromotion(null);
    setIsPromoPaymentOpen(false);
    setIsPaymentOpen(false);
  };

  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains("payment-popup-background")) {
      closePaymentPopup();
    }
  };

  return (
    <div className="sorteio-page">
      <div className="sorteio-content">
        <div className="sorteio-img">
          <img src={sorteioData?.imageURL} alt="Imagem do sorteio" />
          <h1 className="sorteio-title">{sorteioData?.titulo}</h1>
        </div>
        <h6 className="h6-desc">Descrição do sorteio</h6>

        <div className="sorteio-descricao">
          <pre>{sorteioData?.descricao}</pre>
        </div>

        <br />
        {showWarning && (
          <h1 className="warning-message">
            Restam apenas cerca de 20% das cotas disponíveis!
          </h1>
        )}
        <div className="conteudo-sorteio">
          {Object.keys(promotions).length > 0 && (
            <div className="promo-conteudo">
              <h1>Se liga que tem desconto!</h1>
              <p>Basta selecionar a opção que desejar!</p>
              <div className="promo-buttons">
                {Object.entries(promotions)
                  .sort(([, a], [, b]) => b.totalCotas - a.totalCotas)
                  .map(([cotas, info]) => (
                    <button
                      key={cotas}
                      onClick={() => {
                        if (userUID) {
                          setIsPromoPaymentOpen(true);
                          setSelectedPromotionCount(info.totalCotas);
                          setSelectedPromotionTotal(info.valor);
                          openPaymentPopup();
                        } else {
                          handleOpenLogin();
                        }
                      }}
                    >
                      {`${info.totalCotas} cotas por R$${info.valor}`}
                    </button>
                  ))}
              </div>
            </div>
          )}
          <div className="card-cotas">
            <div className="sorteio-data">
              <p>
                Data do sorteio:
                <span>
                  {sorteioData?.dataEncerramento
                    ? format(
                        new Date(sorteioData.dataEncerramento),
                        " dd/MM/yyyy HH:mm"
                      )
                    : " Data padrão"}
                </span>
              </p>
            </div>

            <div className="sorteio-valor">
              <h1>
                Por apenas: <span>R${sorteioData?.valorCota}</span>
              </h1>
            </div>

            <div className="sorteio-msg">
              <p>Quanto mais comprar, maiores as chances de ganhar!</p>
            </div>
            <div className="sorteio-cotas">
              <div className="grid-buttons">
                <button onClick={() => handleButtonClick(40)}>+40</button>
                <button onClick={() => handleButtonClick(60)}>+60</button>
                <button onClick={() => handleButtonClick(100)}>+100</button>
                <button onClick={() => handleButtonClick(200)}>+200</button>
                <button onClick={() => handleButtonClick(300)}>+300</button>
                <button onClick={() => handleButtonClick(500)}>+500</button>
              </div>
              <div className="input-area">
                <input
                  type="number"
                  value={count !== "" ? count : ""}
                  onChange={handleChange}
                  disabled={false}
                />
                <button className="clean-btn" onClick={handleClear}>
                  Limpar
                </button>
              </div>

              <div className="cotas-buttons">
                <button onClick={decrement}>-</button>
                <button onClick={increment}>+</button>
              </div>
              <p className="valor-total">Valor total:</p>
              <h6>R${total.toFixed(2)}</h6>
              {userUID ? (
                <button
                  onClick={openPaymentPopup}
                  className="pay-btn"
                  disabled={cotasEsgotadas}
                >
                  {cotasEsgotadas ? "COTAS ESGOTADAS!" : "PAGAR"}
                </button>
              ) : (
                <button onClick={handleOpenLogin} className="pay-btn">
                  FAÇA LOGIN PARA PROSSEGUIR
                </button>
              )}
            </div>
          </div>
          <div>
            {ranking && ranking.length > 0 ? (
              <>
                <div className="ranking-bg">
                  <h6>RANKING</h6>

                  <div className="ranking">
                    {ranking && ranking.length > 0 ? (
                      ranking.map((usuario, index) => (
                        <div key={index} className="posicao">
                          <img
                            src={icons[index % icons.length]}
                            alt="icone"
                            width={20}
                            height={30}
                          />
                          <p>{`${usuario.username}`}</p>

                          <div className="quant-cotas">
                            <h6>{`${usuario.totalCotas}`}</h6>
                            <h5>bilhetes</h5>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>Sem dados de ranking disponíveis para este sorteio.</p>
                    )}
                  </div>
                </div>
                <div style={{ maxWidth: 400, padding: 16, marginBottom: 48 }}>
                  <p style={{ color: "#F3F3F3", fontSize: 18 }}>
                    O ranking exibe os 3 participantes com mais bilhetes
                    adquiridos. Se alguém tiver a mesma quantidade que você, a
                    posição é mantida, pois essa pessoa comprou primeiro!
                  </p>
                </div>
              </>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
      <div style={{ maxWidth: 400, padding: 16, marginBottom: 48 }}>
        <h1 style={{ color: "#F3F3F3", fontSize: 24 }}>RELATAR ERRO</h1>
        <p style={{ color: "#F3F3F3", fontSize: 18 }}>
          Encontrou algum erro? Entre em contato para que possamos ajudar!
        </p>
        <div style={{ maxWidth: 400, padding: 16 }}>
          <div className="contato-btn">
            <button
              style={{
                backgroundColor: "#000",
                color: "#FFF",
                fontSize: 18,
              }}
              onClick={() => abrirLinkWhatsapp()}
            >
              <IoLogoWhatsapp size={28} color="#25D366" />
              ENVIAR MENSAGEM
            </button>
          </div>
        </div>
      </div>
      {isPaymentOpen && (
        <div
          className="payment-popup-background"
          onClick={handleBackgroundClick}
        >
          <div className="payment-popup">
            <div className="popup-content">
              <div className="payment-info">
                <div className="total-cotas-popup">
                  <p>TOTAL DE COTAS:</p>
                  <h1> {count}</h1>
                </div>
                <div className="valor-total-popup">
                  <p>VALOR TOTAL:</p>
                  <h1> R${total.toFixed(2)}</h1>
                </div>
              </div>
              <PaymentForm
                transaction_amount={total}
                userUID={userUID}
                idSorteio={id}
                quantidadeRifas={count}
                closePaymentPopup={closePaymentPopup}
              />
            </div>
          </div>
        </div>
      )}
      {isPromoPaymentOpen && (
        <div
          className="payment-popup-background"
          onClick={handleBackgroundClick}
        >
          <div className="payment-popup">
            <div className="popup-content">
              <div className="payment-info">
                <div className="total-cotas-popup">
                  <p>TOTAL DE COTAS:</p>
                  <h1> {selectedPromotionCount}</h1>
                </div>
                <div className="valor-total-popup">
                  <p>VALOR TOTAL:</p>
                  <h1>{parseFloat(selectedPromotionTotal).toFixed(2)}</h1>
                </div>
              </div>
              <PaymentForm
                transaction_amount={parseFloat(selectedPromotionTotal)}
                userUID={userUID}
                idSorteio={id}
                quantidadeRifas={selectedPromotionCount}
                closePaymentPopup={closePaymentPopup}
              />
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="loading-popup-background" onClick={handleCloseLogin}>
          <div className="login-popup" onClick={handleFormClick}>
            <div className="login-popup-content">
              <div className="loading">
                <CircularProgress size={48} style={{ color: "#FFF" }} />
                <h1>Carregando...</h1>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoginOpen && (
        <div className="login-popup-background" onClick={handleCloseLogin}>
          <div className="login-popup" onClick={handleFormClick}>
            <div className="login-popup-content">
              <Login handleCloseLogin={handleCloseLogin} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SorteioPage;

import "./WinnerCard.css";

function WinnerCard({ imageURL, username, premio, cota }) {
  return (
    <div className="recent-winners-cards">
      <div className="winner-card">
        <div className="winner-card-img">
          <img src={imageURL || "fallback-image-url"} alt="" width={50} />
        </div>
        <div className="winner-information">
          <h6>{username}</h6>
          <p>{premio}</p>
          <p>15/05/2024</p>
        </div>
        <div className="winner-cota">
          <p>Cota:</p>
          <p>
            <span>{cota}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default WinnerCard;
